::-webkit-input-placeholder {
  opacity: .35;
}

:-ms-input-placeholder {
  opacity: .35;
}

::placeholder {
  opacity: .35;
}

.form-field {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $dark_cc;
  border-radius: 0;
  color: $dark;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  display: block;
  transition: $transition;
  width: 100%;
}

input:hover,
input:focus,
input:active {
  // border: none;
  border-color: $dark_aa !important;
  outline: none !important;
  box-shadow: none !important;

}

textarea:hover,
textarea:focus,
textarea:active {
  outline: none !important;
  box-shadow: none !important;
}

/****/
.form-search {
  position: relative;
  width: 300px;

  & i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $dark_aa;
    font-size: 1.3rem;
  }
}

.form-control {
  // background-color: transparent;
  //border: none;
  border: 1px solid $dark_dd;
  border-radius: 4px;
  color: $dark;
 //font-size: 1rem;
 font-size: 0.8rem !important;// changed in UI fixes by Aman
}
.form-control-xs
{
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.3rem;
  font-size: 0.65rem !important;
  border-radius: 0.2rem;
  cursor: text;
}
.form-control:active {
  outline: none;
  box-shadow: none;
}

.form-label {
  //text-transform: uppercase;
  color: $dark-700;
  margin-bottom: 0.1rem;
  font-size: 0.875rem;
}

.textDes {
  height: 165px;
}

textarea.form-control {
  min-height: 68px;
}

.checkTag {
  list-style: none;
  align-items: center;
  display: inline-flex !important;
  margin-right: 15px;
  text-transform: none !important;
  font-size: 0.85rem !important;
  cursor: pointer;

  & i {
    font-size: 1rem;
    margin-right: 5px;
  }

  & input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  & span {
    border-radius: 20px;
    padding: 6px 16px;
    background: rgba($dark, 0.02);
    text-align: center;
    color: $dark !important;
    align-items: center;
    display: flex !important;
    border: 1px solid #eee;
  }

  & input:checked+span {
    color: #fff !important;
    background: $primary;
    border-color: $primary;
    box-shadow: 1px 1px 2px rgba($dark, 0.2);
  }

  &:hover {
    & span {
      border-color: $primary;
      color: $primary !important;
    }
  }
}

.form-select {
  background-position: calc(100% - 6px) center;
  background-size: 14px 12px;
  padding-right: 2rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23777777%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");

}

select {
  appearance: none;
  -webkit-appearance: none;
  text-overflow: ellipsis;
  padding-right: 22px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10'%3e%3cpath fill='%232e2e2e' d='M14.1,1.1c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L8.6,8.9c-0.3,0.3-0.9,0.3-1.2,0L0.7,2.3c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L8,7.2L14.1,1.1z'/%3e%3c/svg%3e");
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  background-size: 10px 10px;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.lightIcon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10'%3e%3cpath fill='%23fff' d='M14.1,1.1c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L8.6,8.9c-0.3,0.3-0.9,0.3-1.2,0L0.7,2.3c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L8,7.2L14.1,1.1z'/%3e%3c/svg%3e");
  }

  &.form-control {

    &[size],
    &[multiple] {
      height: auto;
    }
  }
}

.tagLabel {
  font-size: 12px;
  display: block;
  opacity: .75;

  &.rightSide {
    margin-left: 62px;
  }
}

.blockList {
  white-space: nowrap;
  overflow: auto;


  &.leftSide {
    margin-right: 52px;
    margin-left: -22px;
  }

  &.rightSide {
    margin-left: 52px;
    margin-right: -22px;

  }

  & .checkTag {
    font-size: 74% !important;
    margin: 0 5px;

    & span {
      background-color: $light;
      padding: 6px 12px;
      margin: 0 auto;
      text-transform: capitalize;
      border-color: $primary;
      color: $primary !important;
    }
  }
}

.input-group-prepend {
  &.disabled {
    opacity: .15;
    // filter: grayscale(100%);
    pointer-events: none;
  }
}

.input-group {

  & .form-control {
    background: rgba($light, .2);
    color: $dark;
    font-size: 13px;
  }

  & .btn {
    min-width: 40px;

    & b {
      margin: -3px 0;
      font-size: 16px;
    }
  }
}


.filterWrap {
  padding-bottom: 1.75rem;
  margin: 0 auto;
  color: darken($primary, 30%);

  & h4 {
    font-size: 1.15rem;
    color: $dark-400;
    font-weight: 400;
    margin-bottom: .85rem;
    opacity: 1;
  }

  & .border-top {
    border-top-color: rgba($dark, .1) !important;
  }


  &.active {
    display: block;
  }
}

.input-group {
  & .input-group-text {
    background-color: transparent;
    border-color: transparent;
  }

  & .form-select,
  & .form-control {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
}

.noteText {
  opacity: .5;
  color: $dark-900;
  display: block;
}


.form-check-input {
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }

  &:focus {
    border-color: lighten($primary, 25%) !important;
    ///box-shadow: 0 0 0 0.25rem lighten($primary, 25%) !important;
    //background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#fda988'/></svg>") !important;
  }
}


.read-only{
  background-color: lightgrey;
}

.editor-container {
  height: none;
}


.react-toast-notifications__toast-container {
  z-index: 5000;
}

.fileupload{
  font-weight:bold;
}


.backgroundfieldColor{
  display: flex;
  align-items: center;
}
.coloricon{
  margin-right: 10px;
}
.colorcode{
  margin-left: auto;
}

.instructions-top{
  height: 408px;
}

.buttonUploadandView{
  padding-top: 5px;
}
.buttonspacing{
  margin-right: 5px;
}
.chcklistdesc{
   height: 81px;
}