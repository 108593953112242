/** VARIABLE **/
@import '../base/variables';

/** HEADER **/
header {
  padding: 10px 0;
  background-color: $light;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1003;
  border-bottom: 1px solid $brand-color;
  box-shadow: 0 0 5px $brand-color;

  & nav {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    padding-right: 15px;

    & ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & li {
        padding: 0 12px;
        list-style: none;

        & a {
          color: $dark-400;
          text-decoration: none;
          cursor: pointer;

          &.active {
            color: $primary;
            font-weight: 400;
          }

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  & .cmyLogo {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
   // padding-left: 10px;

    & a {
      display: block;
      width: 68px;
    }

    & span {
      font-size: 26px;
      font-weight: 300;
      line-height: 30px;
      height: 26px;
      color: $dark-100;
      display: block;
      margin-left: 15px;
      font-family:  $fallbackfont;
      overflow: visible;
    position: relative;
    bottom: 10px;

    }
& .processName
{

    color: $Mettalic-color;
    font-size: 18px;
    position: absolute;
    top: 8px;
    margin-left: 75px;
    line-height: 36px;
    border-left: 2px solid $dark-ee;
    padding-left: 8px;
    display: inline-flex;
    & .arrow{
      vertical-align: revert;
      position: relative;
      top: 0.55rem;
    }
    & .pageName{
      color: $brand-color;
    font-size: 16px;
    font-weight: 400;
    }
     & .subPageName{
      color: $secondary;
    font-size: 15px;
    font-weight: 300;
     }
}

& .noProcessName
{
  position: inherit;
  margin-left: 180px;
  
    background-color: #fb4e0b12;
    
    font-size: small;
    width: inherit;
}
& .pwdExpiryMsg
{
  position: inherit;
  margin-left: 300px;
  
    background-color: #fb4e0b12;
    
    font-size: small;
    width: inherit;
}
& .editProcessIcon
{
  width: 75%;
  height: 75%;
  position: relative;
    top: 0.2rem;
 &:hover
 {
  background-color: #f0f0f0;
    width: 77%;
    height: 77%;
 }
}
& .editProcessDiv{
  display: inline-block;
  cursor: pointer;
}

& .selectProcessDiv
{
  background: #ffd500;
    transform: rotate(2deg);
    top: -1px;
    border-radius: 20% 25% 20% 24%;
   
    display: inline-block;
    cursor: pointer;
}
    & img {
      display: block;
      margin: 0 auto;
    }
  }

  & .breakTimer {
    font-size: 1.2rem;
    text-decoration: none;
    color: $dark-900;
    display: inline-flex;
    padding: .15rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark-fa;
    background-color: $dark-f9;
    cursor: pointer;
    transition: $transition;

    &:hover {
      color: $primary;
      box-shadow: 0 0 0 4px rgba($primary, .1);
      background-color: $light;

    }

    &.active {
      position: relative;
      border-color: rgba($primary,.25);

      &:after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $primary;
        display: block;
        position: absolute;
        right: 0;
        top: -2px;

      }

    }
  }


   .client-logo
  {WIDTH: 80%;
    height: 80%;
    cursor: pointer;
    transition: all 200ms ease-in-out 0s;
    border-radius: 50%;
   
  }

  & .userDropdown {
    & .dropdown-menu {
      position: absolute;
      max-width: 18rem;      
      padding: 0.5rem;
    }   
       
    & .dropdown-menu-end {
      right: 0;
      left: auto;
    }

    & .dropdown-menu {
      top: 100%;
      margin-top: .625rem;
    }

    & .dropdown-account {
      border: none;
      box-shadow: 0 0 22px rgba($dark, .1);
      right: 0;
    }
  }


  & .userDropdown {
    font-size: 1rem;

    & .navbar-dropdown-account-wrapper {
      display: inline-block;
    }

    & .avatar {
      cursor: pointer;
      transition: $transition;

      &:hover {
        box-shadow: 0 0 0 4px rgba($Mettalic-color, .1);
      }
    }

    & .dropdown-item-text {

      & .avatar {
        width: 2.271rem;
        height: 2.271rem;
      }

      & .avatar-info {
        width: calc(100% - 2.271rem);
        padding-left: 0.714rem;
      }

      & h5 {
        font-weight: 500;
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & .text-body {
        font-size: .8rem;
        color: $dark-900 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    & .dropdown-item {
      color: $dark-600;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        background-color: rgba($primary, .035);
      }

      &:last-child {
        color: $dark-100;
      }
    }

    &.active {
      & .dropdown-account {
        display: block;
        transition: $transition;
      }
    }
  }
}
.support-Icon
{
  font-size: 1.8rem !important;
  vertical-align: sub;
  color: $Mettalic-color;
}
.supportForm {
  &-main {
      padding: 20px;
      color: $Mettalic-light-color;
      max-width: 360px;
      margin: 0 auto;
      background-color: #eee;

      & .brandName {
          color: $dark;
          padding: 0;
          margin: 0;

          & img {
              display: block;
              width: 55px;
              position: relative;
          }
      }

      & h2 {
        font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 0;
          color: $Mettalic-color;
      }

     & .ssoDivision {
          text-align: center;
          border-top: 1px solid $Mettalic-color;
          padding: 0px;
          line-height: 0.1em;
          margin: 30px 0px;
          font-size: 14px;
         color: $Mettalic-color;

          & span {
              background: #eee;
              padding: 0px 5px;
          }
      }

      & .signText {
          font-size: 18px;
          font-weight: 300;
          padding-top: 60px;
          color: $dark-500;

          & a {
              font-weight: 400;
              text-decoration: underline;
          }
      }

      & ul {
          list-style: none;
          margin: 0;
          padding: 0;

          & li {
              padding-bottom: 20px;
              list-style: none;

              &:last-child {
                  padding-top: 5px;
                  padding-bottom: 0;
              }
          }

          & .forgotText {
              color: $Mettalic-light-color;
              text-decoration: none;
              text-align: right;
              display: block;
              font-size: 14px;
              font-weight: 400;
              padding-top: 4px;
          }

          & label {
              font-size: 12px;
              text-transform: uppercase;
              display: block;
              color:Black;
              padding-bottom: 4.5px;
             // opacity: 0.75;
          }

          & input {
              font-size: 0.8em !important;
          }

          ::-webkit-input-placeholder {
              opacity: 0.35;
          }

          ::-moz-placeholder {
              opacity: 0.35;
          }

          :-ms-input-placeholder {
              opacity: 0.35;
          }

          :-moz-placeholder {
              opacity: 0.35;
          }

          & .btn {
              font-size: 14px;
              text-transform: uppercase;
              min-width: 125px;

              &:hover {
                  color: #fc6b33;
                  background: #fdfdfd;
                  border-color: #fc6b33;
              }
          }
      }
  }
} 
.dropdown-menu-news {
  position: absolute;
  max-width: 54rem;
  min-width: 28rem;
  padding: 0.5rem;      
  box-shadow: none !important;
} 
 .carousel-caption {
  max-height: 14rem; 
  overflow-y: auto;
  text-align: justify;
  padding-right: 10px;
}
.colorBlack{
  color: black;
}