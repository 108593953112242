.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #fb4e0b;
  border-top: 10px solid #f3f3f3;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.display-none {
  display: none;
}

.containerfigma {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: #e1dede7a;
  width: 91vw;
}
.loading-spinnerfigma {
  background-size: 10%;
  background-repeat: repeat;
  height: 100%;
  width: 6000px;
  transform: translate(-600px);
  animation: move 4s linear infinite;
  background: repeating-linear-gradient(
    to right,
    #fb4e0b 100%
    /* #1abcfe 0%,
    #a259ff 10%,
    #000000 20%,
    #926e64 30%,
    #ffb6e6 40%,
    #a3deff 50%,
    #ffffff 60%,
    #ff0000 70%,
    #ffe462 80%,
    #0acf83 90%,
    #1abcfe 100% */
  );
}

.spinner-containerfigma {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 5px;
  overflow: hidden;
  border-radius: 30px;
  background: #fff;
  transform: scale(0);
  transform-origin: 0 0;
  animation: progress 40s cubic-bezier(0.01, 1.06, 0.71, 1) forwards;
  transition: all 0.3s;
}
body {
  background: #f5f5f5;
}
.loader-body {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 5px;
  overflow: hidden;
  border-radius: 30px;
  background: #fff;
}
.loader-width {
  overflow: hidden;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  animation: progress 40s cubic-bezier(0.01, 1.06, 0.71, 1) forwards;
  transition: all 0.3s;
}
.loader {
  background: repeating-linear-gradient(
    to right,
    #fb4e0b  0%,
    #fb4e0b  10%,
    #eeeeee 20%,
    #fb4e0b  30%,
    #fb4e0b  40%,
    #fb4e0b 50%,
    #eeeeee 60%,
    #fb4e0b 70%,
    #fb4e0b 80%,
    #fb4e0b 90%,
    #fb4e0b 100%
  );
  background-size: 10%;
  background-repeat: repeat;
  height: 100%;
  width: 6000px;
  transform: translate(-600px);
  animation: move 4s linear infinite;
}
@keyframes move {
  0% {
    transform: translate(-600px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes progress {
  0% {
    transform: translate(0.001);
  }
  100% {
    transform: translate(0.97);
  }
}

progress.inactivity {
  height: 24px;
  width: 100%;
  border: 1px solid #fff;
}
progress.inactivity::-webkit-progress-bar {
  background-color: #f3f3f3;
}
progress.inactivity::-webkit-progress-value {
  background-color: #FB4E0B;
}