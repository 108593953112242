/**** LOGIN *****/

.authCenter {
  padding: 75px 0;
  min-height: 100vh;
  background-image: url('../../images/bg/12244.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $dark-fd;
 // display: flex;
  align-items: center;
  justify-content: center;
 
  & h1{
    color: $brand-color;
    margin-left: 173px;
    text-decoration: underline;
    background-color: rgb(249 249 249);
    width: fit-content;
  }
}

.authWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1260px;

  & .authOnly {
    width: 50%;
  }

  & .authLeft {
    width: 48%;
  }

  & .authRight {
    width: 36%;
    border-left: 1px solid $Mettalic-color;

  }
  & .aboutRight {
    width: 36%;

  }
}
.aboutDetail
{
  padding: 40px;
  max-width: 560px;
  margin: 0 auto;
  & h1 {
    font-size: 40px;
    font-weight: 400;
    color: $brand-color;
    margin-bottom: 10px;
    margin-left: 0 !important;
    text-decoration: none;
    background-color: rgb(249 249 249);

    &:first-child {
      padding-top: 0;
    }
  }
 
}

.aboutImage {
  padding: 40px;
  max-width: 560px;
  margin: 0 auto;
  background-image: url('../../images/bg/About-Main.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 425px 250px;
  height: 30rem;
}
.authDetail {
  padding: 40px;
  max-width: 560px;
  margin: 0 auto;
  background-image: url('../../images/bg/Invoice-amico-2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 440px 400px;
  height: 30rem;

  & h1 {
    font-size: 40px;
    font-weight: 400;
    color: $brand-color;
    margin-bottom: 10px;


    &:first-child {
      padding-top: 0;
    }
  }

  & p {
    font-size: 14px;
    font-weight: 300;
    //color: White !important;
    margin-bottom: 10px;
  }

  & small {
    font-size: 14px;
    font-weight: 300;
    color: $dark-200;
    margin-bottom: 0;
  }
  
}


.scWrap {
  padding-top: 25px;

  &-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
  }

  &-icon {
    max-width: 50px;
    display: block;
  }

  &-text {
    margin: 0 !important;
    width: calc(100% - 50px);
    padding-left: 25px;
    font-weight: 300 !important;
    color: $dark-400 !important;
    font-size: 15px !important;
  }

}


.authForm {
    &-main {
        padding: 40px;
        color: $Mettalic-light-color;
        max-width: 370px;
        margin: 0 auto;
        box-shadow: rgb(0 0 0 / 6%) 0px 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 4px 8px, rgba(0, 0, 0, 0.1) 0px 8px 16px;        margin-left: 68px;
        background-color:#eee;

        & .brandName {
            color: $dark;
            padding: 0;
            margin: 0;

            & img {
                display: block;
                width: 55px;
                position: relative;
            }
        }

        & h2 {
            font-size: 35px;
            display: block;
            padding-bottom: 30px;
            margin-bottom: 0;
            font-weight: 300;
            color: $Mettalic-color;
        }

       & .ssoDivision {
            text-align: center;
            border-top: 1px solid $Mettalic-color;
            padding: 0px;
            line-height: 0.1em;
            margin: 30px 0px;
            font-size: 14px;
            color: $Mettalic-color;

            & span {
                background: #eee;
                padding: 0px 5px;
            }
        }

        & .signText {
            font-size: 18px;
            font-weight: 300;
            padding-top: 60px;
            color: $dark-500;

            & a {
                font-weight: 400;
                text-decoration: underline;
            }
        }

        & ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & li {
                padding-bottom: 20px;
                list-style: none;

                &:last-child {
                    padding-top: 5px;
                    padding-bottom: 0;
                }
            }

            & .forgotText {
              //  color: $Mettalic-light-color;
                text-decoration: none;
                text-align: right;
                display: block;
                font-size: 14px;
                font-weight: 400;
                padding-top: 4px;
            }

            & label {
                font-size: 12px;
                text-transform: uppercase;
                display: block;
                color:Black;
                padding-bottom: 4.5px;
               // opacity: 0.75;
            }

            & input {
                font-size: 0.8em !important;
            }

            ::-webkit-input-placeholder {
                opacity: 0.35;
            }

            ::-moz-placeholder {
                opacity: 0.35;
            }

            :-ms-input-placeholder {
                opacity: 0.35;
            }

            :-moz-placeholder {
                opacity: 0.35;
            }

            & .btn {
                font-size: 14px;
                text-transform: uppercase;
                min-width: 125px;

                &:hover {
                    color: #fc6b33;
                    background: #fdfdfd;
                    border-color: #fc6b33;
                }
            }
        }
    }
}