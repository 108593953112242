// Body Reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  
  body {
    font-family: $familyDefault, $fallbackfont;
    font-size: 14px;
    font-variant-ligatures: none;
    font-feature-settings: "liga", 0;
    color: $dark;
  }
  
  ul {
    margin-bottom: 0;
  }
  
  a {
    color: $primary;
  }
  
  img {
    max-width: 100%;
    border: none;
  }
  
  .hidden {
    display: none;
  }
  
  .mobileonly {
    @media #{$desktop} {
      display: none !important;
    }
  }
  
  .tabletonly {
    @media #{$desktop-lg} {
      display: none !important;
    }
  }
  
  .desktoponly {
    display: none !important;
  
    @media #{$desktop} {
      display: inline-block !important;
    }
  }
  
  .text-primary {
    color: $primary !important;
  }
  .text-secondary {
    color: $secondary !important;
  }
  form {
    & small {
      &.error {
        clear: both;
      }
    }
  }

  .text-right {
    text-align: right;
  }


  