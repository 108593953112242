.dropdown-header {
    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: .05rem;
    padding-bottom: .05rem;
    opacity: .75;
}

.dropdown-divider {
    margin-left: .05rem;
    margin-right: .05rem;
}



.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23777777%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 12px;
    width: 14px;
    height: 12px;
    border: none;
    vertical-align: middle;
}