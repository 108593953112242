@import '../base/variables';
.email-content 
{
  height: 55px !important;
}
.email-list-item
{
  margin: 0rem 0.3rem 0.3rem 0.3rem;
  transition: opacity 0.5s ease;
}
.email-list-item.active1
{
  border-left: 3px solid $secondary !important;
}
.email-list-item.hide
{
 opacity: 0;
}
.email-content.active1
{
  border-left-color: $secondary!important;
}
.email-to-text
{
  font-size: 12px !important;
}