.daterangepicker {
    font-family: $familyDefault, $fallbackfont !important;

    & td {
        &.active {
            background-color: $primary !important;
            color: $light;

            &:hover {
                background-color: $primary !important;
                color: $light;

            }
        }

        &.in-range {
            background-color: rgba($primary, .15) !important;
            &.active {
                background-color: $primary !important;
                color: $light;

    
                &:hover {
                    background-color: $primary !important;
                    color: $light;

                }
            }
        }
    }

    & .calendar-table {
        & th,
        & td {
            font-size: 14px !important;

        }
    }
}

