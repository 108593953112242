/** VARIABLE **/
@import '../base/variables';

/** FOOTER **/
footer {
  background-color: rgba($light, 1);
  padding: 7px 0;
  color: $dark-op-60;
  font-size: 14px;
  font-weight: 400;
  padding-left: $navMinWidth;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: $transition;

  & small {
    display: block;
  }

  & a {
    text-decoration: none;
    color: $dark-700 !important;
    margin-right: 1.25rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
      color: $dark !important;
    }
  }
}