html,
body {
  height: 100%;
  overflow: auto;
}

body {
  & #root {
    position: relative;
  }
}

.centerSection {
  margin: 0 auto;
  padding-left: calc($navMinWidth + 1.25rem);
  padding-right: 1.25rem;
  padding-top: calc(34px + 1rem);
  padding-bottom: calc(30px);
  min-height: 100vh;
  transition: $transition;
  background-color: $dark-f6;
}

.mini-mode + .centerSection {
  padding-left: calc($navMinWidth + 1.25rem);
}

.mini-mode + .centerSection + footer {
  padding-left: $navMinWidth;
}

.mini-mode + .centerSection .fieldFooter {
  left: $navMinWidth;
  width: calc(100% - $navMinWidth);
}

.accordWrap {
  border-bottom: 1px solid $dark-f9;
  background: $dark-f5;
  margin: 0 auto;
  margin-bottom: 15px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  &-toggle {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 300;
    color: $primary;
    font-size: 30px;
  }

  &-head {
    padding: 12px 20px;
    position: relative;

    & h2 {
      font-size: 18px;
      color: $dark;
      font-weight: 300;
      margin: 0;
    }
  }

  &-body {
    border-top: 1px solid $dark-op-05;
    padding: 15px 20px;
    display: none;

    &.active {
      display: block;
    }

    & .form-control {
      background: rgba($light, 0.75);
    }
  }
}

.page {
  &Header {
    padding: 0.65rem 0;
    padding-bottom: 1.15rem;
    border-bottom: 1px solid $dark-dd;

    &.highLight {
      margin: -1rem -1.25rem;
      background-color: $dark-300;
      //  background: linear-gradient(75deg, rgba(51,51,51,1) 0%, rgba(17,17,17,1) 100%) url('../../images/bg/bg_dashboard.png');

      background-image: url("../../images/bg/bg_dashboard.png");
      background-position: center;
      background-size: cover;
      border: none;
    }

    & h2 {
      font-weight: 400;
      color: $dark-100;
      font-size: 1.614rem;
      margin-bottom: 0.2rem;

      & span {
        font-weight: 500;
        color: $primary;
      }
    }

    & .breadcrumb {
      margin-bottom: 0;
    }

    &.highLight {
      margin: -1rem -1.25rem;
      background-color: $dark-300;
      background-image: url("../../images/bg/bg_dashboard.png");
      background-position: center;
      background-size: cover;
      border: none;
      & h2 {
        color: $light;
      }

      & .timeCountdown {
        & label {
          color: rgba($light, 0.75);
        }
      }
    }
  }

  &Body {
    padding: 1.0rem 0;
  }
}

// .highLight + .pageBody {
//   margin-top: -6rem;
// }

.userBox {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $dark;

  &:hover {
    color: $primary;
  }

  &-img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    overflow: hidden;

    & img {
      display: block;
      max-width: 100%;
    }
  }

  &-info {
    font-size: 1rem;
    font-weight: 500;
    width: calc(100% - 30px);
    min-width: 60px;
    padding-left: 0.75rem;
  }
}

.statusBox {
  & ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 328px;
    margin: 0 auto;
    padding: 0;
    padding-right: 8rem;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;

    & li {
      list-style: none;
      position: relative;
      color: $dark-aa;

      & b {
        display: block;
        background-color: $light;
        padding: 0 0.15rem;
        opacity: 0.5;
      }

      &:after {
        content: "";
        width: 22px;
        height: 2px;
        background-color: rgba($dark-cc, 0.5);
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: block;
      }

      & span {
        position: absolute;
        left: 50%;
        top: 1rem;
        transform: translateX(-50%);
        font-size: 0.875rem;
        color: inherit;
        display: none;
      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      &:nth-child(1) {
        z-index: 3;
      }

      &:nth-child(2) {
        z-index: 2;
      }

      &:nth-child(3) {
        z-index: 1;
      }

      &.active,
      &.done {
        &[data-status="Assigned"] {
          color: $primary;
        }

        &[data-status="Progress"] {
          color: $orange;
        }

        &[data-status="Review"] {
          color: $secondary;
        }

        &[data-status="Success"] {
          color: $green;
        }

        &[data-status="Pending"] {
          color: $red;
        }
      }

      &.active {
        & b {
          opacity: 1;
        }

        & span {
          display: block;
        }
      }
    }
  }
}

.emptyWrap {
  padding: 5rem 1rem;

  & .emptyBox {
    text-align: center;

    &-icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $dark-f9;
      margin: 0 auto;
      padding: 1.5rem;

      & img {
        display: block;
        margin: 0 auto;
        max-height: 100%;
      }
    }

    &-title {
      font-size: 18px;
      color: $dark-300;
      padding: 1.5rem 0;
    }
  }
}

.formWrap {
  padding: 0 1.071rem;

  & h4 {
    font-size: 1.15rem;
    font-weight: 400;
    color: $dark-500;
    position: relative;
  }

  & hr {
    background-color: $dark-aa;
  }
}

.formWrapFooter {
  position: fixed;
  bottom: 0;
  left: $navWidth;
  width: calc(100% - $navWidth);
  padding: 1rem 1.5rem;
  background-color: $light;
  z-index: 4;
  transition: $transition;

  & .btn {
    min-width: 120px;
  }
}

.modalSubTitle {
  font-size: 1.15rem;
  color: $dark-300;
  font-weight: 400;
}

.timeCountdown {
  font-size: 1.15rem;
  line-height: normal;
  padding: 0 0.5rem;

  & label {
    font-size: 74%;
    display: block;
    opacity: 0.9;
    font-weight: 300;
    line-height: 10px;
  }
  & p,
  & span {
    font-weight: 500;
    color: $primary;
  }
}
.form-inputs
{
  .form-control{
    font-size: 0.8rem;
  }
}
.form-label{
  white-space: nowrap;
  overflow: hidden;
  width: 100% !important;
  text-overflow: ellipsis;
  }

  .full-screen-loader {
    position: absolute;
    width: 100%;
    height: 100vh;
    margin: auto;
    z-index: 9999;
    background-color: rgba(219, 219, 219, 0.2);
    cursor:wait;
  }
  .queue-marginleft{
    margin-left: 2%;
  }