.modal-dialog {
    &.modal-25w {
        max-width: 540px;
    }

    &.modal-80w {
        max-width: 840px;
    }
    &.modal-100w {
        max-width: 88%;
    }

}


.modal-footer {
    & .btn-primary {
        min-width: 100px;
    }
}