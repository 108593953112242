.avatar {
	position: relative;
	display: inline-block;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	&:not(img) {
		background-color: #fff;
	}
}
.avatar-img {
	max-width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: .5rem;
}
.avatar-initials {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	width: 2.625rem;
	height: 2.625rem;
	font-size: .875rem;
	font-weight: 600;
	pointer-events: none;
	text-transform: uppercase;
	border-radius: .5rem;
}
.avatar-circle {
	border-radius: 50%;
	.avatar {
		border-radius: 50%;
	}
	.avatar-img {
		border-radius: 50%;
	}
	.avatar-initials {
		border-radius: 50%;
	}
}
.avatar-centered {
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
}
.avatar-group {
	position: relative;
	z-index: 2;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	.avatar {
		&:hover {
			z-index: 2;
		}
		.avatar-img {
			border: 2px solid #fff;
		}
		.avatar-initials {
			border: 2px solid #fff;
			width: 2.625rem;
			height: 2.625rem;
			font-size: .875rem;
		}
		width: 2.625rem;
		height: 2.625rem;
	}
	.avatar-circle {
		.avatar-initials {
			border-radius: 50%;
		}
	}
	.avatar-sm {
		.avatar-img {
			border: 2px solid #fff;
		}
		.avatar-initials {
			border: 2px solid #fff;
		}
	}
	.avatar-xs {
		.avatar-img {
			border: 2px solid #fff;
		}
		.avatar-initials {
			border: 2px solid #fff;
		}
	}
	.avatar-lg {
		.avatar-img {
			border: 5px solid #fff;
		}
		.avatar-initials {
			border: 5px solid #fff;
		}
	}
	.avatar-xl {
		.avatar-img {
			border: 7px solid #fff;
		}
		.avatar-initials {
			border: 7px solid #fff;
		}
	}
	.avatar+.avatar {
		margin-left: -1rem;
		margin-left: -1rem;
	}
	.avatar-xs+.avatar-xs {
		margin-left: -.6125rem;
	}
	.avatar-sm+.avatar-sm {
		margin-left: -.875rem;
	}
	.avatar-lg+.avatar-lg {
		margin-left: -1.5rem;
	}
	.avatar-xl+.avatar-xl {
		margin-left: -2rem;
	}
}
.avatar-group-xs {
	.avatar {
		width: 1.53125rem;
		height: 1.53125rem;
		.avatar-initials {
			width: 1.53125rem;
			height: 1.53125rem;
			font-size: .65625rem;
		}
	}
	.avatar+.avatar {
		margin-left: -.6125rem;
	}
}
.avatar-group-sm {
	.avatar {
		width: 2.40625rem;
		height: 2.40625rem;
		.avatar-initials {
			width: 2.40625rem;
			height: 2.40625rem;
			font-size: .875rem;
		}
	}
	.avatar+.avatar {
		margin-left: -.875rem;
	}
}
.avatar-group-lg {
	.avatar {
		width: 3.36875rem;
		height: 3.36875rem;
		.avatar-initials {
			width: 3.36875rem;
			height: 3.36875rem;
			font-size: 1rem;
		}
	}
	.avatar+.avatar {
		margin-left: -1.5rem;
	}
}
.avatar-group-xl {
	.avatar {
		width: 4.92188rem;
		height: 4.92188rem;
		.avatar-initials {
			width: 4.92188rem;
			height: 4.92188rem;
			font-size: 1.75rem;
		}
	}
	.avatar+.avatar {
		margin-left: -1.5rem;
	}
}
.avatar-group-xxl {
	.avatar {
		width: 7.875rem;
		height: 7.875rem;
		.avatar-initials {
			width: 7.875rem;
			height: 7.875rem;
			font-size: 2.625rem;
		}
	}
	.avatar+.avatar {
		margin-left: -2rem;
	}
}
.avatar.avatar-circle {
	.avatar-status {
		bottom: -.19141rem;
		right: -.19141rem;
	}
	.avatar-sm-status {
		bottom: 0;
		right: 0;
	}
	.avatar-lg-status {
		bottom: -.28437rem;
		right: -.28437rem;
	}
}
.avatar-xss {
	width: 1rem;
	height: 1rem;
	.avatar-initials {
		width: 1rem;
		height: 1rem;
		font-size: .65625rem;
	}
	.avatar-img {
		width: 1rem;
	}
}
.avatar-xs {
	width: 1.53125rem;
	height: 1.53125rem;
	width: 1.53125rem;
	height: 1.53125rem;
	.avatar-initials {
		width: 1.53125rem;
		height: 1.53125rem;
		font-size: .65625rem;
		width: 1.53125rem;
		height: 1.53125rem;
		font-size: .65625rem;
	}
	.avatar-img {
		width: 1.53125rem;
		width: 1.53125rem;
	}
}
.avatar-xs.avatar-circle {
	.avatar-status {
		bottom: -.19141rem;
		right: -.19141rem;
		bottom: -.19141rem;
		right: -.19141rem;
	}
	.avatar-sm-status {
		bottom: -.10254rem;
		right: -.10254rem;
		bottom: -.10254rem;
		right: -.10254rem;
	}
	.avatar-lg-status {
		bottom: -.28437rem;
		right: -.28437rem;
		bottom: -.28437rem;
		right: -.28437rem;
	}
}
.avatar-sm {
	width: 40px;
	height: 40px;
	.avatar-initials {
		width: 2.40625rem;
		height: 2.40625rem;
		font-size: .875rem;
	}
	.avatar-img {
		width: 100%;
	}
}
.avatar-sm.avatar-circle {
	.avatar-status {
		bottom: -.19141rem;
		right: -.19141rem;
	}
	.avatar-sm-status {
		bottom: -.08203rem;
		right: -.08203rem;
	}
	.avatar-lg-status {
		bottom: -.35547rem;
		right: -.35547rem;
	}
}
.avatar-lg {
	width: 3.36875rem;
	height: 3.36875rem;
	.avatar-initials {
		width: 3.36875rem;
		height: 3.36875rem;
		font-size: 1rem;
	}
	.avatar-img {
		width: 3.36875rem;
	}
	.avatar-uploader-icon {
		font-size: .65625rem;
		width: 1.3125rem;
		height: 1.3125rem;
	}
}
.avatar-lg.avatar-circle {
	.avatar-status {
		bottom: -.11484rem;
		right: -.11484rem;
	}
	.avatar-sm-status {
		bottom: .08203rem;
		right: .08203rem;
	}
	.avatar-lg-status {
		bottom: -.17773rem;
		right: -.17773rem;
	}
}
.avatar-xl {
	width: 4.92188rem;
	height: 4.92188rem;
	.avatar-initials {
		width: 4.92188rem;
		height: 4.92188rem;
		font-size: 1.75rem;
	}
	.avatar-img {
		width: 4.92188rem;
	}
	.avatar-uploader-icon {
		font-size: .8125rem;
		width: 1.53125rem;
		height: 1.53125rem;
	}
}
.avatar-xl.avatar-circle {
	.avatar-status {
		bottom: .14355rem;
		right: .14355rem;
	}
	.avatar-sm-status {
		bottom: .20508rem;
		right: .20508rem;
	}
	.avatar-lg-status {
		bottom: .11849rem;
		right: .11849rem;
	}
}
.avatar-xxl {
	width: 7.875rem;
	height: 7.875rem;
	.avatar-initials {
		width: 7.875rem;
		height: 7.875rem;
		font-size: 2.625rem;
	}
	.avatar-img {
		width: 7.875rem;
	}
	.avatar-uploader-icon {
		width: 2.40625rem;
		height: 2.40625rem;
	}
}
.avatar-xxl.avatar-circle {
	.avatar-status {
		bottom: .65625rem;
		right: .65625rem;
	}
	.avatar-sm-status {
		bottom: .76562rem;
		right: .76562rem;
	}
	.avatar-lg-status {
		bottom: .56875rem;
		right: .56875rem;
	}
}
.avatar-status {
	position: absolute;
	bottom: -.45937rem;
	right: -.45937rem;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	white-space: nowrap;
	text-align: center;
	vertical-align: baseline;
	border: .125rem solid #fff;
	width: 1.14844rem;
	height: 1.14844rem;
	line-height: 1;
	font-size: .65625rem;
	border-radius: 50%;
}
.avatar-sm-status {
	bottom: -.22969rem;
	right: -.22969rem;
	width: .82031rem;
	height: .82031rem;
	font-size: .4375rem;
}
.avatar-lg-status {
	width: 1.42188rem;
	height: 1.42188rem;
	font-size: .65625rem;
}
.avatar-primary {
	.avatar-initials {
		color: #fff;
		background-color: #377dff;
	}
}
.avatar-status-primary {
	color: #fff;
	background-color: #377dff;
}
.avatar-secondary {
	.avatar-initials {
		color: #fff;
		background-color: #71869d;
	}
}
.avatar-status-secondary {
	color: #fff;
	background-color: #71869d;
}
.avatar-success {
	.avatar-initials {
		color: #fff;
		background-color: #00c9a7;
	}
}
.avatar-status-success {
	color: #fff;
	background-color: #00c9a7;
}
.avatar-info {
	.avatar-initials {
		color: #fff;
		background-color: #09a5be;
	}
}
.avatar-status-info {
	color: #fff;
	background-color: #09a5be;
}
.avatar-warning {
	.avatar-initials {
		color: #000;
		background-color: #f5ca99;
	}
}
.avatar-status-warning {
	color: #000;
	background-color: #f5ca99;
}
.avatar-danger {
	.avatar-initials {
		color: #fff;
		background-color: #ed4c78;
	}
}
.avatar-status-danger {
	color: #fff;
	background-color: #ed4c78;
}
.avatar-light {
	.avatar-initials {
		color: #000;
		background-color: #f9fafc;
	}
}
.avatar-status-light {
	color: #000;
	background-color: #f9fafc;
}
.avatar-dark {
	.avatar-initials {
		color: #fff;
		background-color: #132144;
	}
}
.avatar-status-dark {
	color: #fff;
	background-color: #132144;
}
.avatar-soft-primary {
	.avatar-initials {
		color: #377dff;
		background-color: rgba(55, 125, 255, .1);
	}
}
.avatar-status-soft-primary {
	color: #377dff;
	background-color: rgba(55, 125, 255, .1);
}
.avatar-soft-secondary {
	.avatar-initials {
		color: #71869d;
		background-color: rgba(113, 134, 157, .1);
	}
}
.avatar-status-soft-secondary {
	color: #71869d;
	background-color: rgba(113, 134, 157, .1);
}
.avatar-soft-success {
	.avatar-initials {
		color: #00c9a7;
		background-color: rgba(0, 201, 167, .1);
	}
}
.avatar-status-soft-success {
	color: #00c9a7;
	background-color: rgba(0, 201, 167, .1);
}
.avatar-soft-info {
	.avatar-initials {
		color: #09a5be;
		background-color: rgba(9, 165, 190, .1);
	}
}
.avatar-status-soft-info {
	color: #09a5be;
	background-color: rgba(9, 165, 190, .1);
}
.avatar-soft-warning {
	.avatar-initials {
		color: #f5ca99;
		background-color: rgba(245, 202, 153, .1);
	}
}
.avatar-status-soft-warning {
	color: #f5ca99;
	background-color: rgba(245, 202, 153, .1);
}
.avatar-soft-danger {
	.avatar-initials {
		color: #ed4c78;
		background-color: rgba(237, 76, 120, .1);
	}
}
.avatar-status-soft-danger {
	color: #ed4c78;
	background-color: rgba(237, 76, 120, .1);
}
.avatar-soft-light {
	.avatar-initials {
		color: #f9fafc;
		background-color: rgba(249, 250, 252, .1);
	}
}
.avatar-status-soft-light {
	color: #f9fafc;
	background-color: rgba(249, 250, 252, .1);
}
.avatar-soft-dark {
	.avatar-initials {
		color: #132144;
		background-color: rgba(19, 33, 68, .1);
	}
}
.avatar-status-soft-dark {
	color: #132144;
	background-color: rgba(19, 33, 68, .1);
}
.avatar.avatar-4x3 {
	width: 3.5rem;
	height: auto;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-xss.avatar-4x3 {
	width: 1.33333rem;
	height: 1rem;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-xs.avatar-4x3 {
	width: 2.04167rem;
	height: 1.53125rem;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-sm.avatar-4x3 {
	width: 3.20833rem;
	height: auto;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-lg.avatar-4x3 {
	width: 4.49167rem;
	height: auto;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-xl.avatar-4x3 {
	width: 6.5625rem;
	height: auto;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-xxl.avatar-4x3 {
	width: 10.5rem;
	height: auto;
	border-radius: 0;
	.avatar-img {
		height: inherit;
	}
}
.avatar-uploader {
	cursor: pointer;
	display: inline-block;
	transition: .2s;
	margin-bottom: 0;
	&:hover {
		.avatar-uploader-icon {
			color: #fff;
			background-color: #377dff;
		}
	}
}
.avatar-uploader-input {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(19, 33, 68, .25);
	border-radius: 50%;
	transition: .2s;
}
.avatar-uploader-trigger {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
	border-radius: 50%;
}
.avatar-uploader-icon {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	color: #677788;
	background-color: #fff;
	border-radius: 50%;
	transition: .2s;
}
.bell{
	width: 24px;
	transition: transform 0.3s ease;
}
@keyframes shake{
	0%, 100%{ transform: rotate(0deg) scale(1);}
	25% { transform: rotate(-15deg) scale(1.3);}
	50% { transform: rotate(15deg) scale(1.3);}
	75% { transform: rotate(-15deg) scale(1.3);}
}
.bell.shake {
	animation: shake 0.5s ease-in-out 5;
}