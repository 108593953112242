.btn {
  min-width: 31px;

  &:hover,
  &:focus,
  &.focus {
    box-shadow: none !important;
    outline: none !important;
  }

  &:disabled,
  &.disabled {
    opacity: .15;
    filter: grayscale(100%);
  }

  & b {
    margin-right: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }

  &.active {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: -6px;
      top: -6px;
      width: .875rem;
      height: .875rem;
      border-radius: 50%;
      border: 3px solid $light;
      background-color: $red;
      display: block;
    }
  }
}

.btn-primary {
  color: $light;
  background: $primary;
  border-color: $primary;

  &:hover,
  &:focus,
  &.focus {
    color: $light;
    background: lighten($primary, 8%);
    border-color: lighten($primary, 8%);
  }

  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: $light;
      background: $primary;
      border-color: $primary;
    }
  }

  &:disabled,
  &.disabled {
    color: $light;
    background: lighten($primary, 8%);
    border-color: lighten($primary, 8%);
  }
}


.btn-outline-primary {
  color: $primary;
  border-color: $primary;
  background-color: $light;

&:focus,
&.focus,
  &:hover {
    color: $light;
    background: lighten($primary, 8%);
    border-color: lighten($primary, 8%);
  }

 
  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: lighten($primary, 8%);
      background: transparent;
      border-color: lighten($primary, 8%);
    }
  }

  &:disabled,
  &.disabled {
    color: $light;
    background: lighten($primary, 8%);
    border-color: lighten($primary, 8%);

  }
}


.btn-secondary {
  color: $light;

  &:hover,
  &:focus,
  &.focus {
    color: $light;
  }

  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: $light;
    }
  }

  &:disabled,
  &.disabled {
    color: $light;
  }
}


.btn-outline-secondary {
  color: $secondary;

  &:hover,
  &:focus,
  &.focus {
    color: #fff;
  }

  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: #fff;
    }
  }

  &:disabled,
  &.disabled {
    color: #fff;
  }
}

.btn-outline-ntv {
  color: $dark-700;
  border-color: $dark-dd;

  &:hover,
  &:focus,
  &.focus {
    color: $dark-200;
    border-color: $dark-cc;
    background-color: $dark-cc;
  }

  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: $dark-500;
    }
  }

  &:disabled,
  &.disabled {
    color: $dark-ee;
  }

  &.active {
    color: $primary !important;
  }
}

.btn-link {
  color: $dark;
  text-decoration: none;

  &:hover,
  &:focus,
  &.focus {
    color: $dark;
  }

  &:not(:disabled) {

    &:not(.disabled):active,
    &:not(.disabled).active {
      color: $dark;
    }
  }

  &:disabled,
  &.disabled {
    color: $dark;
  }
}

.custom-refresh-button {
  border-radius: 8px;   
  background-color: #FB4E0B;
  padding: 8px 16px;    
  border: none;        
  cursor: pointer;    
}

.refresh-icon{
  margin-top: 2.2%
}


.generate-report{
  bottom: 80% ;
  right: 10%
}
