.dashBoxs-header-MaxHeight {
max-height: 45px;
}
.dynamicTableStyle {
    max-height: 400px;
    overflow-y: auto;
}


.buttonMarginAS {
    margin-right: 10px;
}

.flex{
    display: flex;
  }
  .colorFB4E0B{
    color: #FB4E0B;
  }
  .marginLeft11{
    margin-left: 11rem;
  }
  .lableWidth30percent{
    width: 30% !important;
  }
  .paddingTop{
    padding-top: 5px;
  }

  .float-right{
    float: right;
  }

  .redColor{
    color:#ff0000;
  }

  .statusComment{
    width: 400px;
  }

  .tableStyle{
    background-color: rgb(206 , 206, 206);
    font-size: 0.75em;
  }

  .MTableToolbarfont{
    font-size: 5px;
  }
  .maxHeight{
    max-height: 45px;
    }
.tableProperty {
z-index: 1;
position: relative;
  }

.tableToolbar {
font-size: 5 px;
}
  .imageAlignment {
    vertical-align: super !important;
  }

  // .paddingTopBottom {
  //   padding-top: 3px;
  //   padding-bottom: 5px;
  // }

  .ASButtonsStyle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    }
.redColor {
    color:#ff0000;
  }

.height81 {
    height: 81px;
    }
  .ASMarginRight {
    margin-right: 5px;
  }

.LeftTopMargin2 {
    margin-left: 10px;
    margin-top: 3px;
    }
  .ASLabelStyle {
    overflow: visible;
    text-overflow: clip;
  }