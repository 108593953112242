/** VARIABLE **/
@import '../base/variables';

/** LEFT SIDE NAV **/
.nav-Seperator{
  align-self: center;
    display: block;
    width: 95%;
    background-color: #ffc9564f;
    height: .1rem;
    border-radius: .1rem;
    margin-bottom: 1rem;
}
.material-symbols-outlined {
  font-size: 20px !important;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.submenu-icon
{
font-size: 18px !important;
min-width: 1.4rem;
    height: 1.5rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.navbar {
  &-left {
    width: $navWidth;
    height: calc(100vh - 54px);
    border-right: 1px solid $dark-dd;
    font-size: 16px;
    padding-top: 0.45rem;
    padding-bottom: 1.25rem;
    //background-color: $dark-fe;
    background-color: $dark-ee;
    position: fixed;
    left: 0;
    top: 54px;
    z-index: 1000;
    transition: $transition;

    & nav {
      font-size: 15px;
      height: 93%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1.05rem;
      padding-left: 1.05rem;
      transition: $transition;

      &>ul {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-top: 10px;

        &>li {
          list-style: none;
          margin-bottom: .2rem;
         // border-bottom: 1px solid rgba($dark-100,.02);
          background-color: rgba($dark, .06);
          border-radius: 5px;
         // padding: 0.3rem;
          font-size: 11px;
          border-left: 3px solid $secondary;
          transition: $transition;
          &:last-child {
            border-bottom: none;
          }
.subMenu-item{
  padding: 0.1rem !important;
}
          &>ul {
            list-style: none;
            margin: 0;
            padding: .15rem 0;
            padding-left: 2rem;
            background-color: rgba($light,.015);
            display: none;
            transition: $transition;

            &>li {
              list-style: none;
              margin-bottom: .2rem;
             
              &:last-child {
                margin-bottom: 0;
              }

              & a {
                color: $dark-400 !important;
                padding: .45rem .5rem !important;
                font-size: 94%;
                transition: $transition;
                &:hover {
                  color: $primary !important;
                  background-color: transparent;
                }

                &.active {
                  background-color: rgba($primary, .035);
                  color: $light !important;
                }
              }
            }
          }
        }
      }

      & a {
        padding: .3rem .1rem !important;
        display: flex;
        align-items: center;
       // color: $dark-500 !important;
        color: $dark-100 !important;
        width: 100%;
        font-size: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: $transition;
        border-radius: 0.286rem;

        & span {
          transition: $transition;
        }

        &:hover {
        //  background-color: rgba($dark, .06);
        color: blue !important;
         cursor: pointer !important;
        }

        & b {
          opacity: .75;
          font-weight: normal;
          padding-right: .95rem;
          top: -1px;
          position: relative;
          font-size: 96%;
         transition: $transition;
        }

        &.nav-toggle {
          position: relative;
          margin-top: 0.4rem;

          &:after {
            font-family: 'icomoon' !important;
           // content: "\e92e";
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            font-size: 15px;
            color: $dark-900;
            transition: $transition;
            line-height: 16px;
          }
        }

        &.active {
          color: $primary !important;
          &:hover {
            background-color: transparent;
          }

          &.nav-toggle {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        &.active+ul {
          display: block;
        }
      }

 
    }


    & .modeToggle {
      width: 2.429rem;
      height: 2.429rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $light;
      border: 1px solid $dark-fa;
      box-shadow: 0 0 18px rgba($dark, .08);
      font-size: 1.4rem;
      color: $primary;
      position: absolute;
      right: -1.214rem;
      bottom: 4.286rem;
      border-radius: 50%;
      text-decoration: none;
      transition: $transition;
    }

    &.mini-mode {
      background-color: $dark-ee;
      width: $navMinWidth;

      & nav {
        height: 60%;
        & a {
          & b {
            font-size: 120%;
            opacity: 1;
            //margin: 0;
          }

          & span {
            opacity: 0;
            display: none;
          }

          &.nav-toggle {

            &:after {
              right: 0;
            }
          }

          &:hover {
            color: $primary !important;
            background-color: transparent;
          }
        }

        &>ul {
          &>li {
            background: none;
    padding: 0;

            &>ul {
              opacity: 0;
              display: none;
            }
          }
        }

       

      }

      & .modeToggle {
        transform: rotate(180deg);
      }
      & .app-name{
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        display: inline-block;
        height: 38%;
        text-orientation: mixed;
        font-size: 14px;
      }
    }
    & .app-name{
    //  writing-mode: vertical-lr;
     // transform: rotate(180deg);
      display: inline-block;
      height: 5%;
      text-orientation: mixed;
      margin: 0.5rem;
margin-left: 0.9rem;
      font-size: 15px;
      font-weight: 600;
      color: $brand-color;
      display: block;
      font-family:  $fallbackfont;
    position: relative;
    text-decoration: underline;

    }
  }
}