

.carousel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30em;
    height: 18em;
    background-color: #c6c3bd;
  }
  .text-part {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 10%;
    padding-bottom: 10%;
    /* background-color: whitesmoke; */
    flex: 7;
    overflow: hidden;
  }
  
  .image-part {
    display: flex;
    flex: 5;
    height: 18em;
    padding-right: 1.75em;
  }
  
  .image-part img {
    width: 100%;
    height: 100% !important;
  }
  .updated-on {
    height: 1.25em;
    display: flex;
    align-items: center;
    padding: 0%;
    margin: 0%;
    color: #8e8989; 
    font-family: 'Arial', sans-serif; 
    font-size: 0.75em; 
    font-weight: bold; 
  }
  .news-title {
    height: 4em;
    line-height: 1.33em;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    font-size: 1.33em;
    font-family: 'Times New Roman', sans-serif ;
    color: #333333; 
    font-weight: bold; 
  }
  
  .news-body {
    max-height: 6em; 
    line-height: 1.5em;
    font-size: 0.84rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 400; 
    color: #666666;
  }
  
  .button-container {
    margin-top: 5%;
    height: 1.25em; 
    display: flex;
    align-items: center;
  }