.slickPrev,
.slickNext {
  width: 2vw;
  height: 2vw;
  cursor: pointer;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: #999;
  padding: 0.3vw;
  z-index: 1;

  &.slick-disabled {
    opacity: 0;
    display: none;
  }

  &:hover {
    background: #aaa;
  }
}

.slickNext {
  right: -0.75vw;
}

.slickPrev {
  left: -0.75vw;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  & li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    height: auto;
    width: auto;
    cursor: pointer;

    & button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 7px;
      height: 7px;
      padding: 2px;
      border-radius: 50%;
      cursor: pointer;
      color: transparent;
      border: 1px solid $dark-aa;
      outline: none;
      background: $dark-fa;
      opacity: 0.75;
      margin: 0 auto;

      &:before {
        display: none;
      }

      &:hover {
        background: $dark-aa;
      }
    }

    &.slick-active {
      & button {
        opacity: 1;
        background: rgba($primary,.25);
        border-color: $primary;
      }
    }
  }
}