/** FONT FAMILY **/
$familyDefault: 'Yantramanav';
$familySecondary:'';
$fallbackfont: sans-serif;

/** MEDIA BREAKPOINTS **/
$desktop-1k: "(min-width: 1920px)";
$desktop-xxl: "(min-width: 1440px)";
$desktop-xl: "(min-width: 1366px)";
$desktop-lg: "(min-width: 1200px)";
$desktop: "(min-width: 992px)";
$tablet: "(min-width: 768px)";
$mobile: "(min-width: 576px)";
$mobile-only: "(max-width: 475px)";

/** BRAND COLORS **/
$brand-color:#FB4E0B;
$Mettalic-color:#808080;
$Mettalic-light-color: #e0e0e0;

$dark: #000;
$dark-100: #111;
$dark-200: #222;
$dark-300: #333;
$dark-400: #444;
$dark-500: #555;
$dark-600: #666;
$dark-700: #777;
$dark-900: #999;
$dark-aa: #aaa;
$dark-cc: #ccc;
$dark-dd: #ddd;
$dark-ee: #eee;

$dark-f1: #f1f1f1;
$dark-f2: #f2f2f2;
$dark-f3: #F3F3F3;
$dark-f4: #f4f4f4;
$dark-f5: #f5f5f5;
$dark-f6: #f6f6f6;
$dark-f9: #f9f9f9;
$dark-fa: #fafafa;
$dark-fb: #fbfbfb;
$dark-fc: #fcfcfc;
$dark-fd: #fdfdfd;
$dark-fe: #fefefe;

$dark-op-100: rgba($dark,1);
$dark-op-80: rgba($dark,.8);
$dark-op-60: rgba($dark,.6);
$dark-op-30: rgba($dark,.3);
$dark-op-10: rgba($dark,.1);
$dark-op-05: rgba($dark,.03);
$dark-op-03: rgba($dark,.03);
$dark-op-01: rgba($dark,.01);


$green-light : #8DC63F;
$green-bg-light : #DEF2D6;
$light: #fff;
$red: #ed4c4c;
$orange: #dfb20d;
$yellow: #FFCB05;
$mustard : #DFC259;
$blue: #6EA8EC;
$green: #24cf6c;


/** COLORS CODE **/
$primary: $brand-color;
$secondary: #0074cd;

$black: $dark-op-100;
$success: $brand-color;
$danger: $red;
$danger-bg: lighten($danger,29%);
$caution: $yellow;
$pointout: $yellow;
$highlight: $blue;
$process: $mustard;


/** EFFECTS **/
$transition : all 200ms ease-in-out;

/** LEFT NAV WIDTH **/
$navWidth : 240px;
$navMinWidth : 68px;

$accordion-button-padding-y:0.5rem;
$accordion-button-padding-x:1.25rem;