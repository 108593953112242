/** VARIABLE **/
@import "../base/variables";

/**** DASHBOARD *****/
.antCard-bottom-border-0 {
  border-bottom: none !important;
}
.antCard {
  border: 1px solid $dark-dd;
  background-color: $light;
  padding: 0.6rem;
  color: $dark;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($dark, 0.08);
  // border-bottom: 3px solid #fb4e0b;

  &[data-activity] {
    border-left: 3px solid $yellow;
  }
  &[data-activity="PreProcessing"] {
    border-left: 3px solid $primary;
  }
  &[data-activity="PostProcessing"] {
    border-left: 3px solid $orange;
  }

  &[data-activity="Processing"] {
    border-left: 3px solid $orange;
  }

  &[data-activity="Reviewer"] {
    border-left: 3px solid $secondary;
  }
  &[data-activity="Client"] {
    border-left: 3px solid $secondary;
  }

  &[data-activity="Closed"] {
    border-left: 3px solid $green;
  }

  &[data-activity="Client(Exception)"] {
    border-left: 3px solid $red;
  }
  &[data-activity="Client(Completed)"] {
    border-left: 3px solid $green;
  }
  & h3 {
    font-size: 0.657rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
    opacity: 0.65;
  }
  & h4 {
    font-size: 1.056rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  &-main {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  &-info {
    width: 50%;
    display: flex;

    & p {
      font-size: 1.229rem;
      font-weight: 500;
      margin-bottom: 0;
    }
    & div {
      font-size: 1.129rem;
      font-weight: 500;
      margin-bottom: 0;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      text-align: center;
      & span {
        font-size: 0.8rem;
        opacity: 0.6;
      }
    }
  }

  &-chart {
    width: 50%;
    height: 55px;

    & img {
      max-width: 125%;
      display: block;
      margin-top: 6px;
    }
  }

  &-pro {
    & p {
      font-size: 0.87rem;
      font-weight: 300;
      margin-bottom: 0;
      color: $dark-400;
      display: flex;
      align-items: center;

      & span {
        padding: 0.05rem 0.35rem;
        border-radius: 4px;
        font-size: 92%;
        margin-right: 0.5rem;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        line-height: normal;

        & b {
          font-size: 115% !important;
          margin-top: -1px;
          margin-right: 0.25rem;
        }

        &.up {
          color: darken($green, 20%);
          background-color: rgba($green, 0.2);
        }

        &.down {
          color: darken($red, 20%);
          background-color: rgba($red, 0.2);
        }

        &.normal {
          color: darken($dark-aa, 20%);
          background-color: rgba($dark-aa, 0.2);
        }
      }
    }
  }
}
.tileCard {
  border: 1px solid $dark-dd;
  background-color: $light;
  padding: 0.6rem;
  color: $dark;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($dark, 0.08); 

  &[data-activity] {
    border-left: 3px solid $orange;
  } 
  & h3 {
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 3px;
    opacity: 0.65;
    text-align: center;
  }
  & h4{
    font-size: 1.056rem;
      font-weight: 400;
      margin-bottom: 0;

  }
  &-main {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  &-info {
    width: 50%;
    & p {
      font-size: 1.229rem;
      font-weight: 500;
      margin-bottom: 0;
    }
    & div{
      font-size: 1.0rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-left: 0.3rem;
      margin-right: 0.3rem;      text-align: center;
      & span{
        font-size: 0.8rem;
    opacity: 0.6;
      }
    }
  }
  &-count {
    font-size: 1.2rem !important;
  }
  &-refresh{
    height: 20px;
    width: 150px
  }
  &-seprator{
    border-left: 2px solid rgb(239, 238, 243);
    height: 50px;
    margin-left: 10px;
    
  }
  &-imgsize{
    //width: 20% !important;
    height: 35px !important;
  }
  &-chart {
    width: 50%;
    height: 55px;

    & img {
      max-width: 125% ;
      display: block;
      margin-top: 6px;
    }
  }

  &-pro {
    & p {
      font-size: 0.87rem;
      font-weight: 300;
      margin-bottom: 0;
      color: $dark-400;
      display: flex;
      align-items: center;

      & span {
        padding: 0.05rem 0.35rem;
        border-radius: 4px;
        font-size: 92%;
        margin-right: 0.5rem;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        line-height: normal;

        & b {
          font-size: 115% !important;
          margin-top: -1px;
          margin-right: 0.25rem;
        }

        &.up {
          color: darken($green, 20%);
          background-color: rgba($green, 0.2);
        }

        &.down {
          color: darken($red, 20%);
          background-color: rgba($red, 0.2);
        }

        &.normal {
          color: darken($dark-aa, 20%);
          background-color: rgba($dark-aa, 0.2);
        }
      }
    }
  }
}
.font-Standard-11
{
  font-size: 11px !important;
}
.dashBoxs {
  border: 1px solid $dark-dd;
  background-color: $light;
  padding: 0.871rem 0;
  color: $dark;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($dark, 0.08);  

  &-header {
    border-bottom: 1px solid $dark-dd;
    padding: 0 1.071rem;
  }

  &-body {
    padding: 0 1.071rem;
  }

  // &.bg-gray {
  //   background-color: $dark-f7;
  // }

  & .chartWrap {
    height: 240px;
    width: 100%;

    & .recharts-tooltip-cursor {
      fill: rgba($dark-aa, 0.15);
    }

    & .recharts-cartesian-grid-horizontal {
      & line {
        stroke: $dark-ee;
      }
    }

    & .recharts-text {
      fill: $dark-aa;
    }

    & .recharts-legend-wrapper {
      top: -20px !important;
    }
  }

  & h3 {
    font-size: 1rem;
    font-weight: 500;
    color: $dark-300;
    margin-bottom: 0;
    text-align: center;
  }

  & .resetData {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    color: $primary;
    cursor: pointer;
    margin-left: 8px;
    border-bottom: 1px dashed $primary;
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
}

.fieldSet {
  background-color: lighten($dark, 95%);
  padding: 0 0.375rem;
  border-radius: 6px;
  border: 1px solid $dark-ee;
  //  margin-left: -1rem;
  //  margin-right: -1rem;
  & h3 {
    font-size: 1rem;
    font-weight: 500;
    color: darken($primary, 35%);
    margin-bottom: 0;
    top: -0.475rem;
    position: relative;
  }
}
.optionWrap {
  padding: 0.5rem;
  color: $dark;
}

.formBox {
  max-width: 760px;
  margin: 0 auto;

  & h4 {
    font-size: 1.15rem;
    font-weight: 400;
    color: $dark-500;
    position: relative;
    padding: 0 1.071rem;
  }
}

.formCover {
  border: 1px solid $dark-dd;
  background-color: $light;
  padding: 1.5rem 1.071rem;
  padding-bottom: 1.071rem;
  color: $dark;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba($dark, 0.08);
}

.dargFile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: $dark-f9;
  margin-top: 5px;
  color: $dark-900;
  font-size: 0.9rem;
  border-radius: 8px;
  border: 1px solid $dark-f1;
  cursor: pointer;

  & p {
    margin: 0;
  }

  & input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    border-radius: 8px;
  }
}
.showPreference {
  font-size: 1.2rem;
  text-decoration: none;
  color: $dark-900;
  display: inline-flex;
  padding: 0.15rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid $dark-fa;
  background-color: $dark-f9;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $primary;
    box-shadow: 0 0 0 4px rgba($primary, 0.1);
    background-color: $light;
  }

  &.active {
    position: relative;
    border-color: rgba($primary, 0.25);

    &:after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $primary;
      display: block;
      position: absolute;
      right: 0;
      top: -2px;
    }
  }
}
.pd-highlight {
  padding: 1.5rem 2.5rem 6rem 2.5rem !important;
}
.pd-0 {
  padding: 0rem !important;
}
.pd-top-0 {
  padding-top: 0rem !important;
}
.no_border {
  border: none !important;
}
.MatTable {
  font-size: 1rem;
  border: none;
  background: $light;
  margin-bottom: 0;

  thead {
    background-color: rgba(251, 78, 11, 0.02);
    text-transform: uppercase;
    color: #842702;
    font-size: 90%;
    tr {
      th {
        border: 1px solid #fdfdfd;
        border-top: none;
        border-color: rgba(192, 188, 186, 0.12);
        background-color: $dark-dd;
        vertical-align: top;
        line-height: 20px !important;


        
        // font-weight: 800;
      }

      :first-child {
        border-left: none;
      }
      span {
        padding-top: 5px;
      }
    }
  }
  
  tbody{
    tr {
      td {
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 2px !important;
        line-height: 25px !important;
      }
    }
    tr:hover {
      background-color: #f6f6f6;
      color: blue;
    }
  }

  .MuiTablePagination-selectIcon {
    margin-top: 3px;
  }

  .MuiTablePagination-toolbar {
    width: 100% !important;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-selectLabel {
    display: none;
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart {
    height: 0.8375rem;
    padding: 10px 10px;
    margin: 0px;
    padding-left: 0px;
  }

  .MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingCheckbox.MuiTableCell-sizeMedium {
    font-weight: inherit;
    width: 60px !important;
    box-sizing: border-box;
  }
  
  .MuiTableHead-root{
    font-weight: inherit;
    position: sticky;
    z-index: 10;
    top:0;
  }

  .MuiOutlinedInput-notchedOutline{
    border-width: 0px;

  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart{
    padding-left: 0px;
  }
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall{
    padding: 0;
    width: 24px !important;
  }
  

}
.MuiTable-root{
  width: 100% !important;
}

.sticky-note {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background: #fdfd96;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  z-index: 1000;
}
.note-header {
  background-color: #dddddd;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

/* styling for @material-table/core (updated version of material-table) */
.material-table-core {
  &.MatTable {
    thead {
      tr {
        th {
          border-color: #fdfdfd !important;
        }
      }
    }

    .MuiTablePagination-toolbar {
      width: 100% !important;
    }

    .MuiTablePagination-spacer {
      display: none;
    }

    .MuiTablePagination-selectLabel {
      display: none;
    }

    
  }
}

.PowerBi{
  width: 100%;
  height: 690px;
  border: 1px solid #ccc;
}

.PowerBiHome {
  width: 100%;
  height: 700px;
  border: 1px solid #ccc;
  padding: 0 !important;
}
.CentersetionHome {
  margin-bottom: 5px;
}
.logo-section {
  padding: 20px 0;
}
#notfound {
  position: relative;
  height: calc(100vh - 150px);
  background-color: $Mettalic-light-color;
  border: 1px solid $Mettalic-color;
  border-radius: 3px;
  // background-image: url("../../images/PowerBINotFoundBG.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 55rem;
  width: 100%;
  text-align: center;
  padding: 0 15px;
  line-height: 1.4;
}

.notfound .notfound-404 {
  & .notfound-title {
    line-height: 5rem;
    font-size: 1.8rem;
    color: $brand-color !important;
  }

  & .notfound-body {
    font-size: 1rem;
    color: $Mettalic-color !important;
  }
}

.notfound .notfound-404 h1 {
  font-family: fredoka one, cursive;
  font-size: 168px;
  margin: 0;
  color: #f34705;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: raleway, sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #737373;
  margin: 0;
  margin-bottom: 20px;
}

.notfound a {
  font-family: raleway, sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 15px;
  text-decoration: none;
  color: #39b1cb;
}

.notfound a .arrow {
  position: relative;
  top: -2px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.notfound .btn-primary {
  color: #fff;
  border-color: #f34705;
  background-color: #f34705;
}
.notfound .btn-primary:hover {
  background-color: #eb4c0e;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 122px;
    line-height: 122px;
  }

  .notfound .notfound-404 h1 {
    font-size: 122px;
  }
}
.export-popup {
  display: block;
  font-size: 0.9rem;
  color: black;
  background-color: rgb(252, 250, 250);
  border: 1px solid rgb(252, 250, 250);
  border-color: rgb(189, 189, 189);
  text-decoration: none;
  cursor: pointer;
}
.export-popup:hover {
  background-color: rgb(189, 189, 189);
}
.box {
  inline-size: 150px;
  text-align: center;
  //border : 1px solid rgb(252, 250, 250);
  border-color: rgb(189, 189, 189);
}
.dot {
  height: 13px;
  width: 13px;
  // background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
  margin-right: 5px;
  vertical-align: inherit;
}
$offcanvas-bg-color: $brand-color;

.fjs-palette-container .fjs-palette-field {
  width: 50px !important;
  height: 48px !important;
}
.fjs-empty-editor-card h2 {
  visibility: hidden;
}
.bjs-powered-by
{
  visibility: hidden;
}
.bjs-container a svg {
  visibility: hidden;
}
.fjs-powered-by a svg {
  visibility: hidden;
}
.fjs-empty-editor-card h2::before {
  content: "Create Workitem Fields";
  visibility: visible;
}
[data-group-id="group-condition"] {
  display: none;
}
[data-group-id="group-layout"] {
  display: none;
}
[data-group-id="group-appearance"] {
  display: none;
}
[data-group-id="group-serialization"] {
  display: none;
}
[data-group-id="group-valuesSource"] {
  display: none;
}
[data-group-id="group-constraints"] {
  display: none;
}

.fjs-empty-editor-card span:nth-of-type(2) {
  display: none;
}
.fjs-empty-editor {
  padding-left: 115px;
}
.djs-palette.two-column.open {
  width: 50px;
}
.MuiBox-root css-1xhj18k > button * {
  color: black !important;
}

.bio-properties-panel * {
  font-size: 12px !important;
}
.fjs-editor-container .fjs-properties-container {
  width: 220px;
}
.bio-properties-panel-header-label {
  content-visibility: hidden;
}
// .css-z7uhs0-MuiStepConnector-line {
//   border-color: #fb4e0b !important;
// }
.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 430px;
}
.CanvasBody {
  flex-direction: column;
  /* display: flex; */
  flex-grow: 1;
  width: 100%;
  height: 400px;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 7%;
    max-width: 250px;
  }
}
.shape-options {
  display: flex;
  flex-direction: column;
  /* border: 1px solid; */
  border: 1px solid #1a192b;
  border-color: #ff0072;
  padding: 20px;
}

/* Common shape styles */

.shape {
  width: 30px; /* Adjust the size of the shape icons as needed */

  height: 30px; /* Adjust the size of the shape icons as needed */

  margin: 5px 0;

  cursor: pointer;
}

/* Individual shape styles */

.side-square {
  background-color: transparent;
  height: 28px;
  width: 29px;
  border: 2px solid #3498db;
}

.side-circle {
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 50%;
}

.side-triangle {
  width: 0;

  height: 0;

  border-left: 15px solid transparent;

  border-right: 15px solid transparent;

  border-bottom: 26px solid #27ae60;
}

.side-round-rectangle {
  width: 34px;

  height: 24px;

  background-color: transparent;
  border: 2px solid #f39c12;
  border-radius: 15px;
}

/* Hexagon */

/* Hexagon */

.side-hexagon {
  width: 30px;

  height: 17.32px;

  background-color: #9b59b6;

  position: relative;

  margin: 5px 0;
}

.side-hexagon::before,
.side-hexagon::after {
  content: "";

  position: absolute;

  width: 0;

  border-left: 15px solid transparent;

  border-right: 15px solid transparent;
}

.side-hexagon::before {
  top: -7.66px;

  border-bottom: 8.66px solid #9b59b6;
}

.side-hexagon::after {
  bottom: -7.66px;

  border-top: 8.66px solid #9b59b6;
}

/* Diamond */

.side-diamond {
  width: 25px;

  height: 25px;

  background-color: transparent;
  border: 2px solid #34495e;
  transform: rotate(45deg);

  margin: 4px 0;
}

/* Arrow Rectangle */

.side-arrow-rectangle {
  width: 25px;

  height: 14px;

  background-color: transparent;
  border: 2px solid #134f9b;

  position: relative;

  margin: 5px 0;
}

.side-arrow-rectangle::before {
  content: "";

  position: absolute;

  width: 0;

  height: 0;

  top: 50%;

  left: 100%;

  transform: translateY(-50%);

  border-top: 7.5px solid transparent;

  border-bottom: 7.5px solid transparent;

  border-left: 12.99px solid #134f9b;
}

.side-parallelogram {
  width: 30px;

  height: 20px;

  background-color: transparent;
  border: 2px solid #35e5ce;
  transform: skew(-20deg);
}

.side-ellipse {
  width: 33px;

  height: 25px;

  background-color: transparent;
  border: 2px solid #abcc2e;
  border-radius: 50%;
}
.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
.bpmn-editor-container {
  display: flex;
  align-items: flex-start; /* Align content to the top */
  height: 385px;
  width: 100%;
}

/* Style the BPMN canvas on the left side */
.bpmn-canvas {
  height: 385px;
  width: 100%; /* Adjust the width as needed */
}

/* Style the properties panel on the right side */
.bpmn-properties-panel {
  height: 385px;
  width: 30%; /* Adjust the width as needed */
}
// .css-za8x2c-MuiButtonBase-root-MuiButton-root {
//   color: #fb4e0b !important;
// }
// .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
//   color: #fb4e0b !important;
// }
// .MuiStepper-root MuiStepper-horizontal css-m5vj9m-MuiStepper-root {
//   height: 25px !important;
// }
// .MuiStep-root MuiStep-horizontal span span svg {
//   color: #fb4e0b;
// }
.MuiFab-Custom {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
  min-height: 36px !important;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 50% !important;
  padding: 0 !important;
  min-width: 0 !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 1050 !important;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #e0e0e0 !important;
  color: #fff !important;
  background-color: #1f63b9 !important;
  float: right;
  bottom: 1rem;
  //right: 1rem;
  left: 5rem;
}
.Floaticon-sub {
  background-color: #1f63b9 !important;
  color: rgb(250, 250, 250) !important;
  text-decoration: none !important;
  border: none !important;
  left: 5rem !important;
  bottom: 1.5rem !important;
  width: 33px !important;
  height: 33px !important;
}
.export-popup{
    display: block;
    font-size: 0.9rem;
    color: black;
    background-color: rgb(252, 250, 250);
    border: 1px solid rgb(252, 250, 250);
    border-color: rgb(189, 189, 189);
    text-decoration: none;
    cursor: pointer;
  }
  .export-popup:hover{
    background-color: rgb(189, 189, 189);
  }
  .box{
    inline-size: 150px;
    text-align: center;
    //border : 1px solid rgb(252, 250, 250);
    border-color: rgb(189, 189, 189);
  }
  .dot {
    height: 13px;
    width: 13px;
    // background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
    margin-right: 5px;
    vertical-align: inherit;
  }
  /*********Added by Pradip***********/
  .ColorPickerDiv{
    padding: 0.005rem 0rem 0.1rem 0.5rem !important;    
  }
  .ColorPickerDiv-first{    
      width: 70% !important; float: left !important; padding-Top: 0.3rem !important;    
  }
  .ColorPickerDiv-second{
    width: 20% !important; float: right !important ;
  }    
  .margin-graph-time{
    margin-right: 0.3rem !important;
  }
  .graph-refresh-icon{
    width: 22px !important;
    height: 22px !important;
  }
  .homeGraph-Refresh-Info
  {
    background-color: rgb(247 247 247) !important;
    color: rgb(50 49 48) !important;
    text-decoration: none;
    border: none;
    border-radius: 10% !important;
    width: fit-content !important;
    height: 20px !important;
    padding: 5px !important;
  }
  .home-floating-container{
    float: right;
    top: 6vh;
    position: fixed;
    margin: 1em;
    right: 10vw;
  }
  .home-floating-icon
  {
    background-color: #acacac !important;
    color: rgb(250, 250, 250);
    text-decoration: none !important;
    border: none !important;
    border-radius: 10% !important;
    width: fit-content !important;
    height: 20px !important;
    padding: 5px !important;
  }
  $offcanvas-bg-color:                $brand-color;

  .label-sub-header
  {
    font-size: 0.75rem;
  }

  .materialTableComponent{
    background-color: rgb(206 206 206);
    font-size: 0.75em;
  }
  .mToolbarFontsize{
    font-size: 5px;
  }

  .multiselect-container.multiSelectContainer {
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 100;
  
}
.search-wrapper.searchWrapper {
  border-radius: 4px;
  min-height: 22px;
  padding: 0px;
  position: relative;
  border: none;
  .searchBox{
    margin-top: 0px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    width: 100%;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.3rem;
    font-size: 0.65rem !important;
    border-radius: 0.2rem;
    cursor: text;
  }
}
.multiSelectContainer {
input {
background: transparent;
border: none;
// margin-top: 0px;
}
}
.chip.false.false {
  align-items: center;
  background: #FB4E0B;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}
.version-item {
  position: relative;
  padding-left: 40px;
}

.timeline-marker {
  position: absolute;
  left: 0;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #007bff;
}

.version-item::before {
  content: '';
  position: absolute;
  left: 6px;
  top: 20px;
  bottom: 0;
  width: 2px;
  background: rgba(0, 0, 0, 0.1);
}
