/** GLOBAL COMPONENTS **/
@import "base/_nvh_variables.scss";

body {
    background-color: #fff !important;
}

.nvh_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .separator {
      flex-grow: 1;
  }
  
  .logo {
      padding: 1.25rem;
  }
  
  .app-initials {
      margin-bottom: 1.25rem;
      height: 3.25rem;
      width: 3.25rem;
      border-radius: var(--border-radius-l);
      background-color: var(--bg-02);
      display: flex;
      align-items: center;
      justify-content: center;
      span {
          margin-top: 0.3rem;
          color: var(--text-color-00);
          font-size: calc(var(--font-size-r) * 1.6);
          font-weight: calc(var(--font-weight) - 0);
      }
  }
  
  .app-name {
      font-size: calc(var(--font-size-r) * 1.5);
      font-weight: calc(var(--font-weight) - 0);
      margin-bottom: 2.5rem;
      color: var(--text-color-00);
  }
  
  .loading span {
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin: 0.4rem;
      background: var(--primary-color);
  }
  
  .loading span {
      animation: bounce 0.6s cubic-bezier(0.6, 0.1, 1, 0.4);
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }
  
  .loading span:nth-child(1) {
      animation-delay: 0.1s;
  }
  
  .loading span:nth-child(2) {
      animation-delay: 0.2s;
  }
  
  .loading span:nth-child(3) {
      animation-delay: 0.3s;
  }
  
  @keyframes bounce {
      to {
          transform: translateY(2rem);
      }
  }
  
  @media screen and (max-width: 800px) {
      .container {
          height: calc(100vh - var(--navbar));
      }
  }
  
  //-------------------platform-logo
  .platform-logo {
    display: flex;
    font-size: 2rem;

    &__box{
      display: flex;
      align-items: center;

      &__logo{
        display: flex;
        justify-content: center;
        color: #fb4e0b;        
        font-weight: bold;
      }
      span {
        display: block;
        height: 100%;
        background-color: var(--border-color);
        width: 0.15rem;
      }
    }
    &__name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &__1 {
        font-weight: 500;
        color: #004f70;
        margin-bottom: 0px;

        sup {
          top: -1.2rem;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }
      &__3 {
        font-weight: 500;
        color: #004f70;
        margin-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .platform-logo {
      &__box{
        &__img{
          height: 1.5rem;
          margin-bottom: 0.2rem;
        }
        span {
          margin: 0rem 0.8rem 0.2rem 0.8rem;
        }
      }
      &__name {
        &__1 {
          line-height: 1.1rem;
          font-size: 1.3rem;
        }
        &__3 {
          line-height: 1.5rem;
          margin: 0rem 0 0 -0.05rem;
          font-size: 1.7rem;
          font-weight: 600;
        }
      }
    }
  }
}