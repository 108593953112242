/** VARIABLE **/
@import '../base/variables';

/** TIME BREAK **/
.timeBreak {

  &-form {
    margin: 0;
    padding: 0.5rem;

    & textarea {
      min-height: 60px;
    }

    & li {
      list-style: none;
      padding: 0.714rem 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

}