.badge {
  font-weight: 400;
  border: 1px solid $dark;
  background-color: rgba($dark, .12);
   font-size: .875rem;
}

.bg-success {
  background-color: rgba($green, .12) !important;
  border-color: $green;
  color: darken($green, 10%);
}

.bg-danger {
  background-color: rgba($red, .12) !important;
  border-color: $red;
  color: darken($red, 10%);
}

.bg-warning {
  background-color: rgba($orange, .12) !important;
  border-color: $orange;
  color: darken($orange, 10%);
}