.split {
    &Screen {
        display: flex;
      //  margin: -1rem -2.25rem;
        height: calc(100vh - 95px);
    }

    &full {
        width: 100%;
        border-right: 1px solid $dark-dd;
        background-color: $dark-fa;
        padding: 1rem;
        overflow: auto;

        & h3 {
            font-size: 1.214rem;
            font-weight: 400;
            color: $primary;
            margin-bottom: 0;
            border-bottom: 1px solid $dark-ee;
        }

        & h4 {
            font-size: .875rem;
            font-weight: 500;
            color:darken($primary,35%);
            margin-bottom: 0;
            top: -.475rem;
            position: relative;
        }


        & .fieldSet {
            background-color: lighten($dark, 95%);
            padding:0 .875rem;
            border-radius:6px;
            border: 1px solid $dark-ee;
          //  margin-left: -1rem;
          //  margin-right: -1rem;

        }
        & .form-label {
            text-transform: none;
        }

        & .fieldFooter {
            position: fixed;
            bottom: 0;
            left: $navMinWidth;
            width: calc(100% - $navMinWidth);
            padding: 0.3rem 0.3rem;
            background-color: $light;
            z-index: 4;
            transition: $transition;
            box-shadow: 0 0 3px #f7bc07;

        }
    }

    &Left {
        width: 100%;
        border-right: 1px solid $dark-dd;
        background-color: $dark-fa;
        padding: 1rem;
        overflow: auto;

        & h3 {
            font-size: 1.214rem;
            font-weight: 400;
            color: $primary;
            margin-bottom: 0;
           // border-bottom: 1px solid $dark-ee;
        }

        & h4 {
            font-size: .875rem;
            font-weight: 500;
            color:darken($primary,35%);
            margin-bottom: 0;
            top: -.475rem;
            position: relative;
        }


        & .fieldSet {
            background-color: lighten($dark, 95%);
            padding:0 .875rem;
            border-radius:6px;
            border: 1px solid $dark-ee;
          //  margin-left: -1rem;
          //  margin-right: -1rem;

        }
        & .form-label {
            text-transform: none;
        }

        & .fieldFooter {
            position: fixed;
            bottom: 0;
            left: $navMinWidth;
            width: calc(100% - $navMinWidth);
            padding: 0.2rem 0.3rem;
            background-color: $light;
            z-index: 4;
            transition: $transition;

        }
       
    }

    &Right {
        width: 100%;
        background-color: #e6e6e6;
        transition: ease-in-out all .9s;
        -webkit-transition: ease-in-out all .9s;
        -moz-transition: ease-in-out all .9s;
        overflow-x: auto;
        // white-space: nowrap;

        & img {
            max-width: 100%;
            display: block;
            margin:0 auto;
        }

    }
}
 .screenicon
{
   // font-size: 1.5rem !important;
    padding-left: 2rem;
    &:hover {
        color: $secondary;
        cursor: pointer;
    }
  
}
.staticfields-section{
   // width: 33% !important;
    margin-right: 0.3rem;
  //  height: 180px !important;
}
.regGroup-title{
    color: $brand-color !important;
    width: fit-content !important;
    background-color: rgb(250, 250, 250) !important;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    border-radius: 5px !important;
}
d-flex
{
    display: flex !important;
}
.width-half
{
    width: 50% !important;
}
.width-30percent
{
    width: 30% !important;
}
.width-33percent
{
    width: 33% !important;
}
.width-40percent{
    width:40% !important;
}
.width-45percent{
    width:45%;
}
.width-full{
    width: 100% !important;
}

.staticfields{
    display: flex;
}

.staticfields-lefinput
{
    margin-right: 1rem;
    padding-top: 0.393rem;
}
.staticfields-rightbutton
{
    margin-right: 0.2rem;
}
.accordion-collapse{
    display: none;
  }
  .show-hide,
  .show-hide1
  {
    display: block !important;
  }
  .accordion-button{
      &::after{
        width:1rem;
        height:1rem;
        background-size: 1rem;
      }
      &.active{
          &::after{
              transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
          }
      }
  }
  .b_0{
      border:none;
  }