.light-mode {
  //primary color hsl(17, 97%, 51%) - To create color pallate add/subtract Lightness by 10
  --primary-color: #fb4e0b;
  
  //secondary colors hsl(222, 100%, 50%) - To create color pallate add/subtract Lightness by 10
  --secondary-color: rgb(0, 76, 255);
  --secondary-color-11: rgb(46, 54, 67); // Slate Gray 1 #2e3643 Can also be used for 2E3641
  --secondary-color-12: rgba(47, 54, 67, 0.7); // Slate Gray 2
  --secondary-color-13: rgba(46, 53, 68, 0.4); // Slate Gray 3
  --secondary-color-16: #BDC4C6; // Gray 6 // rgba(30, 55, 68, 0.295)

  --secondary-color-20: rgb(1, 58, 81); // #013a51
  --secondary-color-21: rgb(0, 79, 112); // Blue 1 // #005071 in color sheet, used #004F70 intead.
  --secondary-color-22: rgba(0,80,113,0.7); // Blue 2
  --secondary-color-23: rgba(0,80,113,0.4); // Blue 3
  --secondary-color-26: rgba(0,80,113,0.12); // Blue _bgr this comes out as #E0EAEE while #E2EAED is in design

  --secondary-color-31: rgba(219,242,250,1); // L_Blue 1
  --secondary-color-32: rgba(220,243,250,0.7); // L_Blue 2
  --secondary-color-33: rgba(220,243,250,0.5); // L_Blue 3

  --secondary-color-41: black;
  --secondary-color-42:#2C2E31;
  --secondary-color-45-d:#767676; // use this istead of #767778
  --secondary-color-45:#808080;
  --secondary-color-451:#80858D; // Avoid This, use 45 instead, 
  --secondary-color-47:#ABABAB;
  --secondary-color-471:#ADB0B5;
  --secondary-color-49:#D9D9D9;

  --secondary-color-51:white;
  --secondary-color-52:#FBF9FC;
  --secondary-color-52:#F1F1F1;

  --secondary-color-61: #079A0D; // Green 1
  --secondary-color-62: #09D611; // Green 2
  // --secondary-color-71: #079A0D; // Green 1
  // --secondary-color-72: #079A0D; // Green 1
  
  //Background color
  --bg-00: hsl(0, 0%, 100%);
  --bg-01: #f5f5f5;
  --bg-02: #e6e6e6;
  --bg-03: #dbdbdb;
  --bg-04: #ababab;
  --bg-05: #808080;
  --bg-06: #414141;
  --bg-07: #2E3641;
  --bg-08: #005071;
  --bg-09: #FFE2D6;

  //text color
  --heading-color: rgb(0, 0, 0);
  --text-color-00: rgb(0, 0, 0);
  --text-color-01: #6c757d;
  --text-color-02: #ffffff;

  //other colors

  --icon-color: #fb4e09;
  --border-color: #e7e7ee;

  --field-border-color: hsl(0, 0%, 67%);
  --focus-border-color: hsl(17, 96%, 73%);
  --shadow-color: rgba(0, 0, 0, 0.1);
  --success-color: #47a44b;
  --danger-color: #f33527;
  --warning-color: #f08f00;
  --info-color: #00aec5;

  // scrollbar colors
  --scrollbar-color: #2E3641;
  --scrollbar-thumb: #F2F1F1;
  --scrollbar-track-piece-color: #2E3641;
  --scrollbar-button: #D9D9D9;
}
.dark-mode {
  //lighten the color using https://pinetools.com/lighten-color

  //20.7% of light theme
  --primary-color: rgb(252, 114, 59);
  
  //35% of light theme
  --secondary-color: rgb(89, 138, 255);
  
  //Background color
  --bg-00: rgb(28, 28, 28);
  --bg-01: rgb(36, 36, 36);
  --bg-02: rgb(48, 48, 48);
  --bg-03: rgb(61, 61, 61);
  --bg-04: rgb(74, 74, 74);
  --bg-05: rgb(87, 87, 87);
  --bg-06: rgb(99, 99, 99);

  //text color
  --heading-color: rgba(255, 255, 255, 0.87);
  --text-color-00: hsl(213, 6%, 71%);
  --text-color-01: rgb(123, 129, 138);

  //other colors
  --icon-color: #969ba1;
  --border-color: hsl(206, 6%, 25%);
  --field-border-color: hsl(0, 0%, 26%);
  --focus-border-color: hsl(17, 97%, 70%);

  --shadow-color: rgba(0, 0, 0, 0.1);
  --success-color: #65bc69; //20% of light theme
  --danger-color: #f55d52; //20% of light theme
  --warning-color: #ffa726; //20% of light theme
  --info-color: #04e1ff; //20% of light theme

}

:root {
  //other
  --font-family: "Yantramanav", sans-serif;
  --font-size-s: 1rem;
  --font-size-r: 1rem;
  --font-size-l: 1rem;
  --font-weight: 400;
  --navbar: 10.8rem;
  --navbar-expanded: 22rem;
  --border-radius: 0.25rem;
  --border-radius-l: 0.5rem;
  --border-radius-xl: 0.75rem;

  @media screen and (max-width: 800px) {
    --font-size-r: 0.9rem;
    --font-size-l: 0.7rem;
    --navbar: 5.8rem;
    --border-radius: 0.25rem;
  }

  @media only screen and (min-width:1200px) and (max-width:1540px) {
    --font-size-s: 0.9rem;
    --font-size-r: 0.9rem;
    --font-size-l: 0.6rem;
    --navbar: 7.8rem;
    --border-radius: 0.25rem;
  }
}
