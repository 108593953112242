.brandInfoTable {
  font-size: 1rem;
  border: none;
  background: $light;
  margin-bottom: 0;

  & th,
  & td {
    padding:.35rem 1rem;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    min-width: 150px;

     & small {
      display: block;
      font-weight: 500;
      opacity: 0.85;
    }
  }

  & tbody {
    color: $dark-300;

    & tr {
      &:nth-child(even) {
        background-color: rgba($dark,.015);

      }
    }
  }

  & thead {
    background-color: rgba($primary, .04);
    text-transform: uppercase;
    color: darken($primary, 25%);
    font-size: 94%;

    & tr {
      background-color: inherit;

      & th {
        font-weight: 400;
        border: 1px solid $dark_fd;
        border-top: none;
        border-color: rgba($primary, .12);
        background-color: inherit;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        & b {
          font-size: 90%;
          color: rgba($dark, .75);
          margin-left: .025rem;
        }
      }
    }
  }

}


.brandTable {
  font-size: 1rem;
  border: none;
  background: $light;
  margin-bottom: 0;

  & .btn {
    &.rnd {
      border-radius: 50%;
      display: inline-flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
    }
  }

  & a:not(.btn) {
    color: $primary;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;

    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      filter: grayscale(100%);
    }
  }

  & th,
  & td {
    padding: .85rem 1rem;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    min-width: 200px;
    max-width: 400px;

    &:first-child,
    &.nrwCol {
      min-width: 75px;
    }

    &.actionCol {
      min-width: 140px;
    }

    & small {
      display: block;
      font-weight: 500;
      opacity: 0.85;
    }
  }

  & tbody {
    color: $dark-600;
  }

  & thead {
    background-color: rgba($primary, .02);
    text-transform: uppercase;
    color: darken($primary, 25%);
    font-size: 90%;

    & tr {
      background-color: inherit;

      & th {
        font-weight: 400;
        border: 1px solid $dark_fd;
        border-top: none;
        //border-bottom: none;
        border-color: rgba($primary, .12);
        background-color: inherit;
        vertical-align: top;
        line-height: normal;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        & b {
          font-size: 90%;
          color: rgba($dark, .75);
          margin-left: .025rem;
        }
      }
    }
  }
}


.tableWrap {
  margin: 0 auto;

  &-body {
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  &-footer {
    padding: 1.25rem 1.071rem;
    padding-bottom: 0;
    border-top: 1px solid $dark-f1;

    & .text-info {
      color: $dark-700 !important;
      font-weight: 300;

      &:last-child {
        color: $dark-700 !important;
      }
    }

    & .paginate_button {
      border-color: transparent;
      border-radius: 4px;
    }

    & .page-item {
      &.active {
        & .page-link {
          background-color: $primary;
          color: $light;
        }
      }

      &.disabled {
        & .page-link {
          color: $dark-cc;
        }
      }
    }

    & .page-link {
      color: $primary;
      border: none;
      cursor: pointer;
    }
  }
}


.deadLine {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba($green,.75);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: .9rem;
  color: $light;
  margin:0 auto;

  &.over {
    background-color: $red;

  }

}